import * as React from 'react';
import { graphql } from 'gatsby';
import './style.scss';
import SEO from '../../components/seo';
import Default from '../../layouts/default';
import AtlassianPartnerLogo from '../../images/AtlassianPartnerLogo.png';
import SecurityInJira from '../../images/SecurityinJira.png';
import SecurityInJiraMd from '../../images/SecurityinJiraMd.png';
import AtlassianBuildPipeline from '../../images/AtlassianBuildPipeline.png';
import LongArrowLeft from '../../images/long-arrow-left.svg';
import * as GatsbyTypes from 'GatsbyTypes';
import { REQUEST_DEMO_ANCHOR } from 'core/links';
import Container from '../../components/_POC/Container';
import images from '../../images/homepage/index';
import { defaultButtonText } from '../../components/RequestADemoModal/constants';

const AtlassianPartnerPage: React.FC<{ data: any }> = ({ data }) => {
  console.log(data);
  const atlassianPartnerData: GatsbyTypes.ContentfulPage = data.atlassian;
  const atlassianPartnerPage = atlassianPartnerData?.edges[0].node;
  const seo = atlassianPartnerPage.seo;

  return (
    <Default>
      <SEO data={seo} />
      <div className="hero flex-column justify-content-center align-items-center container">
        <div className="hero-section d-flex justify-content-center align-items-center gap-100">
          <div className="d-flex flex-column hero-wrapper">
            <h1>
              <div className="title">
                StackHawk + <br /> Atlassian
              </div>
            </h1>
            <div className="mobile-image py-30">
              <img width="450" src={AtlassianPartnerLogo} alt="StackHawk + Atlassian Partner Logo" />
            </div>
            <p className="summary">
              StackHawk integrates natively into your Atlassian tools and development workflows to help you automate
              application and API security testing to quickly find and fix vulnerabilities.
            </p>
            <a
              href="/blog/stackhawk-atlassian-working-together-to-help-you-shift-left-the-right-way/"
              className="primary-button"
            >
              Learn More
            </a>
          </div>
          <div className="desktop-image">
            <img width="450" src={AtlassianPartnerLogo} alt="StackHawk + Atlassian Partner Logo" />
          </div>
        </div>

        <div className="pt-120">
          <div className="card-container d-flex flex-column flex-md-row align-items-center">
            <div className="card-container-info d-flex flex-column gap-10">
              <div className="title">Security in Jira</div>
              <p className="summary">
                Vulnerability data from StackHawk can be viewed in the new Security tab in Jira, better enabling
                shift-left security testing to find and fix vulnerabilities before they hit production.
              </p>
            </div>
            <div className="desktop-image">
              <img height="400" src={SecurityInJira} alt="Security in Jira" />
            </div>
            <div className="mobile-image">
              <img height="354" src={SecurityInJiraMd} alt="Security in Jira" />
            </div>
          </div>
        </div>

        <div className="pt-32 d-flex align-items-center ">
          <div className="row gap-30">
            <div className="sm-card-container ">
              <div className="title">Increase Visibility</div>
              <p className="summary">
                Improve collaboration between security and development teams by bringing vulnerability data and
                actionable insights from StackHawk into a centralized location within Jira.
              </p>
            </div>
            <div className="sm-card-container ">
              <div className="title">Efficiently Triage</div>
              <p className="summary">
                Quickly link vulnerabilities to Jira issues and create and assign tickets to triage and remediate
                security issues within your existing Jira developer workflows.
              </p>
            </div>
            <div className="sm-card-container ">
              <div className="title">Reduce Risk</div>
              <p className="summary">
                Get a comprehensive, unified view of security issues that pose a risk to your organization within Jira
                Cloud along with the full context from StackHawk needed to quickly prioritize and take action.
              </p>
            </div>
          </div>
        </div>

        <div className="pt-120 w-100">
          <div className="automate-security-testing card-container p-82 d-flex flex-column align-items-center">
            <div className="card-container-info text-center d-flex flex-column gap-10">
              <div className="title m-w-725">
                <span className="title-highlight">Automate Security Testing</span> as part of your Atlassian Build
                Pipeline
              </div>
              <p className="summary-bright m-w-725">
                StackHawk can be run as part of your CI/CD pipeline with Atlassian Bamboo and Bitbucket Cloud.
                Automatically scan applications that have been deployed to your environment and fix security issues in
                your code before pushing to production.{' '}
              </p>
              <a
                className="primary-link d-flex justify-content-center align-items-center gap-10"
                href="https://docs.stackhawk.com/continuous-integration/bamboo.html"
              >
                <div>Learn More</div>
                <img height="24" width="24" src={LongArrowLeft} alt="Long Arrow Left" />
              </a>
            </div>
            <div>
              <img width="100%" src={AtlassianBuildPipeline} alt="Atlassian Build Pipeline" />
            </div>
          </div>
        </div>

        <div className="containerPaddingLarge">
          <Container
            image={images.StackHawkInFlight}
            button1Text={defaultButtonText}
            button1Link={REQUEST_DEMO_ANCHOR}
            variant="wide5050"
            heading="Want to know how <br />StackHawk can improve <br/>your API Security and <br/>AppSec Programs?"
            description="Schedule time with our experts for a live demo."
            cssClass="mobileHideImg"
          />
        </div>

        <div className="containerPaddingSmall">
          <Container
            heading="Get Hands-On Experience <br/> <span>Give Us A Test Drive!</span>"
            description="We know you might want to test drive a full version of security software before you talk to us. So, Get It On!"
            button1Text="Start Your Free Trial"
            button1Link="https://auth.stackhawk.com/signup"
            cssClass="hawksomeCard"
          />
        </div>
      </div>
    </Default>
  );
};

export default AtlassianPartnerPage;

export const pageQuery = graphql`
  {
    atlassian: allContentfulPage(filter: { page: { eq: "Atlassian" } }) {
      edges {
        node {
          seo {
            ...ContentfulSeo
          }
          ...defaultFields
        }
      }
    }
  }
`;
